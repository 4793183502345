import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import {graphql} from "gatsby";
import {Carousel, Col, Row} from "antd";
import PageHeader from "../components/PageHeader";
import Img from "gatsby-image";
import './partners.less';

class Partners extends Component {
    render() {
        const page = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        let slides = "";
        let headerImage = "";
        let depth = "";

        if(acfFields.header_images !== null && acfFields.header_images !== undefined) {

            if (acfFields.header_images.length > 1) {
                slides = [];
                acfFields.header_images.forEach(element =>
                    slides.push(element.localFile?.childImageSharp?.fluid)
                );
            }

            headerImage = acfFields.header_images[0].localFile?.childImageSharp?.fluid;
            depth = "full";

        } else {

            headerImage = this.props.data.allWordpressAcfOptions.edges[0].node.options?.default_header_image?.localFile?.childImageSharp?.fluid;
            depth = "thin";

        }

        const settings = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            autoplaySpeed: 10000,
            swipeToSlide: true,
        };

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile?.childImageSharp?.original?.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image?.localFile?.childImageSharp?.original?.src : null}
                path={`/${path}`}
                locale={page.translations.current}
                translations={page.translations}
            >
                <PageHeader
                    title = {acfFields.display_header}
                    videoID = {acfFields.header_video_id}
                    image = {headerImage}
                    slides = {slides}
                    headerColor = {acfFields.header_colour}
                    headerStyle = {acfFields.header_style}
                    back = ""
                    date = ""
                    depth = {depth}
                />
                {acfFields.partner_blocks && acfFields.partner_blocks.map((partner_blocks) => {
                    return (
                        <div className="partners-page">
                            <Row gutter={70} type="flex">
                                <Col xs={{span: 24}} md={{span: 22, push: 1}} lg={{span: 18, push: 3}}>
                                    <h2><span>{partner_blocks.pre_header} </span>{partner_blocks.header}</h2>
                                    <Img fluid={partner_blocks.logo_image.localFile?.childImageSharp?.fluid}/>
                                    <div dangerouslySetInnerHTML={{__html: partner_blocks.top_content}} />
                                </Col>
                                <Col xs={{span: 24}}  lg={{span: 20, push: 2}}>
                                    <Carousel {...settings}>
                                        {partner_blocks.logo_slider && partner_blocks.logo_slider.map((image) => {
                                            return <div className="slide" key={image.id}>
                                                { image != null ?
                                                    <Img fluid={image.localFile?.childImageSharp?.fluid} />
                                                    : null }
                                            </div>
                                        })}
                                    </Carousel>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 22, push: 1}} lg={{span: 18, push: 3}}>
                                    <div dangerouslySetInnerHTML={{__html: partner_blocks.bottom_content}} />
                                </Col>
                            </Row>
                        </div>
                    )
                })}
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
    query QueryPartnerPage($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            slug
            link
            wordpress_id
            wordpress_parent
            title
            content
            translations {
                current
                en
                cy
            }
            acf {
                meta_title
                meta_description
                og_title
                og_description
                og_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                        }
                    }
                }
                twitter_title
                twitter_card_type
                twitter_description
                twitter_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                        }
                    }
                }
        
                header_style
                header_colour
                header_video_id
                header_images {
                  localFile {
                    childImageSharp {
                      original {
                        width
                        height
                        src
                      }
                        fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                            ... GatsbyImageSharpFluid
                        }
                    }
                  }
                }
                display_header
                show_back_link
                
                partner_blocks {
                    pre_header
                    header
                    logo_image {
                        localFile {
                            childImageSharp {
                                original {
                                    width
                                    height
                                    src
                                }
                                fluid {
                                    ... GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    top_content
                    logo_slider {
                        id
                        localFile {
                            childImageSharp {
                                original {
                                    width
                                    height
                                    src
                                }
                                fluid(quality: 80) {
                                    ... GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    bottom_content
                }
            }
        },
        allWordpressAcfOptions {
            edges {
                node {
                    id
                    options {
                        default_header_image {
                            localFile {
                                childImageSharp {
                                    original {
                                        width
                                        height
                                        src
                                    }
                        fluid(quality: 80) {
                            ... GatsbyImageSharpFluid
                        }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default Partners
